import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import JourneySnippet from "./JourneySnippet";
import { maxMobile, minDesktop } from "../styles";

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media(${minDesktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  @media (${maxMobile}) {
    grid-template-columns: 1fr;
  }
`;

class PhotoFeed extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    return (
      <Grid>
        {posts &&
        posts.map(({ node: post }) => (
          <JourneySnippet post={post} alt="Photos" key={post.id} />
        ))}
      </Grid>
    );
  }
}

PhotoFeed.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query PhotosFeedQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              draft: { eq: false }
              templateKey: { eq: "photo-post" }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredPost
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 368, maxHeight: 368, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PhotoFeed data={data} count={count} />}
  />
);
