import React, { Component } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { mainBg } from "../../styles";
import { Context } from "../../state";
import PhotosFeed from "../../components/PhotosFeed";

const Background = styled.div`
  background: ${mainBg};
  padding: 48px 0 64px;
  
   a {
    margin: 0;
  }
`;

class BlogIndexPage extends Component {
  static contextType = Context;

  componentDidMount() {
    const [, dispatch] = this.context;
    dispatch({ type: "PAGE_TYPE", payload: "archive" });
  }

  render() {
    return (
      <Layout>
        <Background>
          <div className="container">
            <PhotosFeed />
          </div>
        </Background>
      </Layout>
    );
  }
}

export default BlogIndexPage;
